<template>
  <b-container class="my-4">
    <b-row class="text-center mb-4" align-h="center" v-if="loading">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center" v-if="!loading && !permission.read">
      <b-col>
        <h3>You do not have permissions for this page</h3>
      </b-col>
    </b-row>
    <b-row v-if="!loading && permission.read">
      <b-col>
        <b-card >
          <b-container>
            <b-row>
              <b-col sm="12" md="8">
                <p><b-link :to="{ name: 'Trainingcases' }">all training cases</b-link></p>
                <h2>
                  <b-button class="mr-2" v-if="permission.edit" variant="outline-primary" :to="{ name: 'FormEdit', params: { id: item.id, model: 'trainingcase' }}">Edit</b-button>
                  {{item.name}}
                </h2>
                <delete-standard
                  v-if="permission.delete && step.delete"
                  :id="item.id"
                  model="trainingcase"
                ></delete-standard>
                <h3 v-if="item.body">Description</h3>
                <div v-if="item.body" v-html="item.body"></div>
              </b-col>
              <b-col>
                <h4>Workflow</h4>
                <div>status: <strong>{{step.name}}</strong></div>
                <div><small>{{item.workflow_created_by}} @ {{new Intl.DateTimeFormat().format(new Date(item.workflow_created_at))}}</small></div>
                <div>
                  <span v-if="!savingStep && permission.edit">
                    <b-button class="mr-4" v-if="step.downStep && permissionWorkflowDown" variant="outline-primary" size="sm" @click="stepUpdate(step.down)">move to: {{step.downStep.name}}</b-button>
                    <b-button v-if="step.upStep && permissionWorkflowUp" variant="outline-primary" size="sm" @click="stepUpdate(step.up)">move to: {{step.upStep.name}}</b-button>
                  </span>
                  <span v-if="savingStep">
                    <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
                  </span>
                </div>
              </b-col>
            </b-row>
            <b-row v-if="item.risks.length > 0 || item.controlrisks.length > 0 || item.controls.length > 0 || item.obligations.length > 0 || item.publications.length > 0">
              <b-col>
                <div v-if="item.controlrisks.length > 0">
                  <h3 v-if="item.body">Control risks</h3>
                  <div v-for="row in item.controlrisks" :key="'controlrisk'+row.id">
                    <strong>{{row.name}}</strong>
                    <div>{{row.description}}</div>
                  </div>
                </div>
                <div v-if="item.risks.length > 0">
                  <h3 v-if="item.body">Market abuse risks</h3>
                  <div v-for="row in item.risks" :key="'marrisk'+row.id">
                    <b-link :to="{ name: 'Risk', params: { id: row.id } }"><strong>{{row.name}}</strong></b-link>
                    <div>{{row.comments}}</div>
                  </div>
                </div>
                <div v-if="item.controls.length > 0">
                  <h3 v-if="item.body">Controls</h3>
                  <div v-for="control in item.controls" :key="'control'+control.id">
                    <b-link :to="{ name: 'Control', params: { id: control.id } }">{{control.name}}</b-link>
                  </div>
                </div>
                <div v-if="item.obligations.length > 0">
                  <h3 v-if="item.body">Obligations</h3>
                  <div v-for="row in item.obligations" :key="'obligation'+row.id">
                    <b-link :to="{ name: 'RegDecompObligation', params: { id: row.id } }">{{row.name}}</b-link>
                  </div>
                </div>
                <div v-if="item.publications.length > 0">
                  <h3 v-if="item.body">News</h3>
                  <div v-for="row in item.publications" :key="'newsitem'+row.id">
                    <b-link :to="{ name: 'NewsItem', params: { id: row.id } }">{{row.name}}</b-link>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ac from '@/libs/accesscontrol'
import moment from 'moment'
import workflow from '@/workflows/trainingcase'
import DeleteStandard from '@/components/DeleteStandard'

export default {
  name: 'Trainingcase',
  components: {
    DeleteStandard
  },
  computed: {
    permissionWorkflowDown: function () {
      let result = false
      if (this.step.id > 7 && ac.can(this.user.acgroups).readAny('trainingcaseWorkflowApprove').granted) {
        result = true
      }
      if (this.step.id <= 7 && ac.can(this.user.acgroups).createAny('trainingcase').granted) {
        result = true
      }
      return result
    },
    permissionWorkflowUp: function () {
      let result = false
      if (this.step.id >= 7 && ac.can(this.user.acgroups).readAny('trainingcaseWorkflowApprove').granted) {
        result = true
      }
      if (this.step.id < 7 && ac.can(this.user.acgroups).createAny('trainingcase').granted) {
        result = true
      }
      return result
    },
    step: function () {
      const step = workflow.find(x => x.id === this.item.workflow_id)
      step.downStep = workflow.find(x => x.id === step.down)
      step.upStep = workflow.find(x => x.id === step.up)
      return step
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'trainingcase', action: 'open trainingcase', model: 'trainingcase', model_id: this.$route.params.id })
    this.permission.read = ac.can(this.user.acgroups).readAny('trainingcase').granted
    this.permission.edit = ac.can(this.user.acgroups).updateAny('trainingcase').granted
    this.permission.delete = ac.can(this.user.acgroups).deleteAny('trainingcase').granted
    this.load()
  },
  data () {
    return {
      loading: true,
      item: null,
      moment: moment,
      permission: {
        delete: false,
        edit: false,
        read: false
      },
      savingStep: false
    }
  },
  methods: {
    load: async function () {
      this.loading = true
      try {
        this.item = await this.$Amplify.API.get('cosmos', `/trainingcase/${this.$route.params.id}`)
        document.title = this.item.name
        this.loading = false
      } catch (e) {
        this.$logger.warn(e)
      }
    },
    stepUpdate: async function (id) {
      this.savingStep = true
      try {
        const params = {
          body: {
            workflow_id: Number.parseInt(id)
          }
        }
        await this.$Amplify.API.put('cosmos', `/standard/trainingcase/${this.item.id}`, params)
        this.item.workflow_id = id
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.savingStep = false
    }
  }
}
</script>

<style scoped>
h3 {
  margin-top: 1.5em;
}
h4 {
  margin-top: 1.5em;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
