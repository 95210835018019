<template>
<b-col>
  <b-container fluid class="bg-white">
    <b-row>
      <b-col class="my-5"><h3>By number of cases</h3></b-col>
    </b-row>
    <b-row cols="2">
      <b-col>
        <div class="mb-4" v-for="chart in barCharts1" :key="chart.key">
          <bar-stacked-simple v-if="Array.isArray(chart.data) && chart.data.length > 0" :config="chart"/>
        </div>
      </b-col>
    </b-row>
  </b-container>
</b-col>
</template>

<script>
import _ from 'lodash'
// import * as echarts from 'echarts'
import dataGetters from '@/libs/dataGetters'
// import theme from '@/libs/colorTheme'
import BarStackedSimple from '@/components/charts/v1/barStackedSimple.vue'
// import BarStackedSeries from '@/components/charts/v1/barStackedSeries.vue'
// echarts.registerTheme('kpmg', theme)

function getHeight (data) {
  return (Math.floor(data.length / 10) + 1) * 140 + 'px'
}

function getRandomKey () {
  return Math.floor(Math.random() * 10000)
}

export default {
  name: 'TrainingcasesDataStrats',
  components: {
    BarStackedSimple
  },
  computed: {
    barCharts1: function () {
      const results = []
      const charts = [
        {
          dataGetter: 'array',
          property: 'controlrisks',
          nameProperty: 'name',
          nameSeries: 'Control risks',
          header: 'Control risks',
          headerRecord: 'risks'
        },
        {
          dataGetter: 'array',
          property: 'risks',
          nameProperty: 'name',
          nameSeries: 'MAR risks',
          header: 'MAR risks',
          headerRecord: 'risks'
        },
        {
          dataGetter: 'array',
          property: 'workflow',
          nameProperty: 'name',
          nameSeries: 'Review status',
          header: 'Review status',
          headerRecord: 'review status'
        }
      ]
      _.each(charts, chart => {
        const data = dataGetters[chart.dataGetter](this.rows, chart)
        const config = {
          data: data,
          name: chart.nameSeries,
          header: chart.header,
          headerRecord: chart.headerRecord,
          height: getHeight(data),
          width: '100%',
          key: getRandomKey()
        }
        results.push(config)
      })
      return results
    },
    rows: function () {
      return this.$store.state.rowsShow
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
  }
}
</script>

<style>
</style>
